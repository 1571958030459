import * as React from 'react'
import {
  Table,
  Form,
  Button,
  Pagination,
  DatePicker,
  message,
  Modal,
  Input,
  Select,
  Tag,
} from 'antd'
import { myOrderList, orderCancel } from '../../service/index'
import { FormComponentProps } from 'antd/lib/form'
import creatHistory from 'history/createHashHistory' //返回上一页这段代码
import InvoiceAdd from './InvoiceAdd'
const history = creatHistory() // hash模式

const { Column } = Table
const { Option } = Select

export interface IInvoiceOrderProps extends FormComponentProps {
  onReload: () => void
}
interface IInvoiceOrderStates {
  dataSource: any
  pageSize: number
  groupId: any
  total: number
  page: number
  checkedList: any
  editInvoiceOrderId: any
  loading: boolean
  orderNums: any
  invoiceAmount: any
  visible: boolean
}

class InvoiceOrder extends React.Component<IInvoiceOrderProps, IInvoiceOrderStates> {
  constructor(props: any) {
    super(props)
    this.getInvoiceOrderList = this.getInvoiceOrderList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.state = {
      pageSize: 10,
      dataSource: [],
      groupId: '',
      total: 0,
      page: 1,
      checkedList: [],
      editInvoiceOrderId: '',
      loading: false,
      orderNums: [],
      invoiceAmount: 0,
      visible: false,
    }
  }
  componentDidMount() {
    this.getInvoiceOrderList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getInvoiceOrderList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      ...values,
      orderStatus: 2,
      payStatus: 2,
      invoiceStatus: 0,
    }
    const res = await myOrderList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getInvoiceOrderList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getInvoiceOrderList()
    }, 0)
  }

  onCancelInvoiceOrder = async (orderId: any) => {
    const res = await orderCancel(orderId)
    if (res.success) {
      message.success('取消订单成功')
      this.getInvoiceOrderList()
    } else {
      message.error('取消订单失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        const orderNums = selectedRows.map((item: any) => {
          return item.orderNum
        })
        let invoiceAmount = 0
        selectedRows.forEach((element: any) => {
          invoiceAmount += element.totalAmount
        })
        this.setState({
          checkedList: selectedRowKeys,
          orderNums: orderNums,
          invoiceAmount: invoiceAmount,
        })
      },
    }

    return (
      <div className="w-full h-full">
        <Modal
          title="开票"
          width={'60%'}
          visible={this.state.visible}
          onOk={() => {
            this.setState({
              visible: false,
            })
          }}
          onCancel={() => {
            this.setState({
              visible: false,
            })
          }}
        >
          <Form layout="inline" style={{ marginBottom: 16 }}>
            <InvoiceAdd
              buttonTitle="开票"
              icon="edit"
              editable
              orderIds={this.state.checkedList.join(',')}
              orderNums={this.state.orderNums.join(',')}
              invoiceAmount={this.state.invoiceAmount}
              onSuccess={() => {
                this.onPageChange(1)
                if (this.props.onReload) {
                  this.props.onReload()
                }
              }}
            />
          </Form>
          <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
            <Form.Item label="订单号">
              {getFieldDecorator('orderNum')(<Input placeholder="订单号" allowClear />)}
            </Form.Item>
            <Form.Item label="培训名称">
              {getFieldDecorator('orderTitle')(<Input placeholder="培训名称" allowClear />)}
            </Form.Item>
            <Form.Item label="类型">
              {getFieldDecorator('orderType')(
                <Select placeholder="请选择" allowClear style={{ width: '100px' }}>
                  <Option key="1">课程 </Option>
                  <Option key="2">项目 </Option>
                  <Option key="3">计划</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="支付方式">
              {getFieldDecorator('payType')(
                <Select placeholder="请选择" allowClear style={{ width: '100px' }}>
                  <Option key="1">扫码</Option>
                  <Option key="2">线下 </Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon="search" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Form>
          <Pagination
            size="small"
            current={this.state.page}
            pageSizeOptions={['10', '20', '50', '100', '200']}
            pageSize={this.state.pageSize}
            showSizeChanger
            showQuickJumper
            total={this.state.total}
            showTotal={this.showTotal}
            onChange={this.onPageChange}
            onShowSizeChange={(current, size) => {
              setTimeout(() => {
                this.setState({
                  pageSize: size,
                })
                this.onPageChange(1)
              }, 0)
            }}
          />
          <Table
            rowSelection={rowSelection}
            rowKey="orderId"
            loading={this.state.loading}
            pagination={{
              current: this.state.page,
              onChange: this.onPageChange,
              pageSize: this.state.pageSize,
              size: 'small',
              total: this.state.total,
              showTotal: this.showTotal,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ['10', '20', '50', '100', '200'],
              onShowSizeChange: (current, size) => {
                setTimeout(() => {
                  this.setState({
                    pageSize: size,
                  })
                  this.onPageChange(1)
                }, 0)
              },
            }}
            dataSource={this.state.dataSource}
          >
            <Column
              title="订单号"
              dataIndex="orderNum"
              key="orderNum"
              render={(orderNum: any) => orderNum}
            />
            <Column
              title="培训名称"
              dataIndex="orderTitle"
              key="orderTitle"
              render={(orderTitle: any) => orderTitle}
            />
            <Column
              title="类型"
              dataIndex="orderType"
              key="orderType"
              render={(orderType: any) => {
                let orderStr = ''
                switch (orderType) {
                  case 1:
                    orderStr = '课程'
                    break
                  case 2:
                    orderStr = '项目'
                    break
                  case 3:
                    orderStr = '计划'
                    break

                  default:
                    break
                }
                return orderStr
              }}
            />
            <Column
              title="人数"
              dataIndex="enrollCount"
              key="enrollCount"
              render={(enrollCount: any) => enrollCount}
            />
            <Column
              title="报名时间"
              dataIndex="regtime"
              key="regtime"
              render={(regtime: any) => regtime}
            />
            <Column
              title="来源"
              dataIndex="source"
              key="source"
              render={(source: any) => {
                let sourceStr = ''
                switch (source) {
                  case 1:
                    sourceStr = '微信端'
                    break
                  case 2:
                    sourceStr = '管理端'
                    break

                  default:
                    break
                }
                return sourceStr
              }}
            />
            <Column
              title="支付方式"
              dataIndex="payType"
              key="payType"
              render={(payType: any) => {
                let payTypeStr = ''
                switch (payType) {
                  case 1:
                    payTypeStr = '扫码支付'
                    break
                  case 2:
                    payTypeStr = '线下支付'
                    break

                  default:
                    break
                }
                return payTypeStr
              }}
            />
            <Column
              title="单价"
              dataIndex="unitPrice"
              key="unitPrice"
              render={(unitPrice: any) => unitPrice || 0}
            />
            <Column
              title="金额"
              dataIndex="totalAmount"
              key="totalAmount"
              render={(totalAmount: any) => totalAmount || 0}
            />
            <Column
              title="开票状态"
              dataIndex="invoiceStatus"
              key="invoiceStatus"
              render={(invoiceStatus: any) => {
                return (
                  <Tag color={invoiceStatus == 1 ? 'green' : 'yellow'}>
                    {invoiceStatus == 1 ? '已开票' : '未开票'}
                  </Tag>
                )
              }}
            />
          </Table>
        </Modal>
        <Button
          type="primary"
          onClick={() => {
            this.setState({
              visible: true,
            })
          }}
        >
          我要开票
        </Button>
      </div>
    )
  }
}

export default Form.create<IInvoiceOrderProps>()(InvoiceOrder)
