import * as React from 'react'
import { getFileType } from '../../utils/index'
import { Button } from 'antd'
import WZFilePreview from '../videoDoc/WZFilePreview'

export interface ICourseChapterListProps {
  chapterList: any
  playListener?: (attach: any) => void
  modal: boolean
  isEnroll: boolean
}

export default class CourseChapterList extends React.Component<ICourseChapterListProps> {
  attachCell(attach: any) {
    const fileType = getFileType(attach.origFileName)
    const { isEnroll } = this.props

    const titleClass = 'chapter-attach ml-s' + (isEnroll ? '' : ' disable')
    return (
      <div className="flex-vcenter mt-m">
        <div className="flex-center">
          <div>
            {fileType === 'video' && (
              <Button
                type="primary"
                disabled={!isEnroll}
                shape="circle"
                icon="video-camera"
                onClick={() => {
                  if (this.props.playListener) {
                    this.props.playListener(attach)
                  }
                }}
              ></Button>
            )}
            {fileType !== 'video' && fileType !== 'image' && (
              <Button
                type="primary"
                disabled={!isEnroll}
                shape="circle"
                icon="file-pdf"
                onClick={() => {
                  if (this.props.playListener) {
                    this.props.playListener(attach)
                  }
                }}
              ></Button>
            )}
            {fileType === 'image' && (
              <Button
                type={isEnroll ? 'primary' : 'default'}
                shape="circle"
                icon="file-image"
                onClick={() => {
                  if (this.props.playListener) {
                    this.props.playListener(attach)
                  }
                }}
              ></Button>
            )}
          </div>
        </div>
        <div className={titleClass}>{attach.origFileName}</div>
      </div>
    )
  }

  attachCellModal(attach: any) {
    return (
      <div className="flex-vcenter mt-m">
        <div className="flex-center">
          <WZFilePreview
            url={attach.textDirectURL}
            pdfUrl={attach.textPdfFaceURL}
            fileName={attach.origFileName}
            modal={this.props.modal}
          ></WZFilePreview>
        </div>
        <div className="chapter-attach ml-s">{attach.origFileName}</div>
      </div>
    )
  }

  public render() {
    const { chapterList } = this.props
    return (
      <div className="cc-card-body">
        {chapterList &&
          chapterList.map((chapter: any) => (
            <div className="chapter-cell">
              <div className="chapter-title mt-s">{chapter.title}</div>
              {!this.props.modal &&
                chapter.chapTextJson &&
                chapter.chapTextJson.map((item: any) => this.attachCell(item))}
              {this.props.modal &&
                chapter.chapTextJson &&
                chapter.chapTextJson.map((item: any) => this.attachCellModal(item))}
            </div>
          ))}
      </div>
    )
  }
}
