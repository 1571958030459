import React, { Component } from 'react'
import { Descriptions, Divider, Button, Card, Radio, message } from 'antd'
import { orderOne, qrCodePay } from './../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

const win: any = window
const QRCode: any = win.QRCode || {}

export interface IOrderPayProps extends RouteComponentProps<any> {}
interface IOrderPayState {
  orderId: any
  order: any
  loading: boolean
  tokenCodeURL: any
  qrCode: any
  qrCodeEl: any
  payType: any
}

class OrderPay extends Component<IOrderPayProps, IOrderPayState> {
  constructor(props: any) {
    super(props)
    this.state = {
      orderId: props.query.orderId,
      order: {},
      loading: false,
      tokenCodeURL: '',
      qrCode: null,
      qrCodeEl: <div id="qrcode"></div>,
      payType: 1,
    }
  }

  componentDidMount() {
    this.setState({
      payType: 1,
    })
    this.getOrder()
  }

  getOrder = async () => {
    if (this.state.orderId) {
      this.setState({ loading: true })
      const res = await orderOne(this.state.orderId)
      if (res.msgStatsNum === 10) {
        message.warn('您还未登录！')
        return
      }
      if (res.success) {
        const order = res.data[0]
        if (order) {
          this.setState({ order: order })
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    const { order } = this.state
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    return (
      <div className="app_layout_main">
        <Card className="mt-m">
          <Descriptions title={order.orderTitle} column={1}>
            <Descriptions.Item label="商品订单">{order.orderNum}</Descriptions.Item>
            <Descriptions.Item label="订单名称">{order.orderTitle}</Descriptions.Item>
            <Descriptions.Item label="单价">{order.unitPrice}</Descriptions.Item>
            <Descriptions.Item label="人数">{order.enrollCount}</Descriptions.Item>
            <Descriptions.Item label="合计金额">{order.totalAmount}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions title="支付方式" column={1}></Descriptions>
          <Radio.Group
            value={this.state.payType}
            onChange={(e) => {
              setTimeout(() => {
                this.setState({
                  payType: e.target.value,
                })
              }, 0)
            }}
          >
            <Radio style={radioStyle} value={1}>
              手机扫码付款
            </Radio>
            <Radio style={radioStyle} value={2}>
              银行汇款
            </Radio>
          </Radio.Group>
          <Descriptions column={1}>
            <Descriptions.Item label="备注">&nbsp;</Descriptions.Item>
          </Descriptions>
          <p className="tips">
            1.
            如选用银行汇款方式支付，在汇款时需在备注信息里填写发票抬头，汇款完成后，点击“下一步”，填写付款信息并上传付款凭证。
          </p>
          <p className="tips">
            2.
            支付完成或提交付款凭证后，工作人员将根据付款信息进行信息审核，已支付学员信息审核后，即可登录“学习平台”进行学习
          </p>
          <Link
            to={
              '/app/train/orderPayResult?type=' +
              this.state.payType +
              '&orderId=' +
              this.state.orderId
            }
            color="gray"
          >
            <Button className="mt-m" type="primary">
              下一步
            </Button>
          </Link>
        </Card>
      </div>
    )
  }
}
export default withRouter(OrderPay)
