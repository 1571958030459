/**
 * 路由组件出口文件
 * yezi 2018年6月24日
 */
import Dashboard from './dashboard/Dashboard'
import Training from './training/Training'
import StudyCenter from './studyCenter/StudyCenter'
import WZPdf from './videoDoc/WZPdf'
import TrainList from './training/TrainList'
import CourseDetail from './course/CourseDetail'
import PlanDetail from './plan/PlanDetail'
import TrainDetail from './train/TrainDetail'
import UserCenter from './userCenter/UserCenter'
import OrderConfirm from './order/OrderConfirm'
import OrderDetail from './order/OrderDetail'
import OrderPay from './order/OrderPay'
import OrderPayResult from './order/OrderPayResult'
import HotRecommendList from './dashboard/HotRecommendList'
import HotCourse from './dashboard/HotCourse'
import ExpertList from './experts/ExpertList'
import ChannelList from './channel/ChannelList'
import ExpertDetail from './experts/ExpertDetail'
import Login from './pages/Login'
import InvoiceOrder from './order/InvoiceOrder'

export default {
  Dashboard,
  WZPdf,
  Training,
  TrainList,
  StudyCenter,
  CourseDetail,
  PlanDetail,
  TrainDetail,
  UserCenter,
  OrderConfirm,
  OrderDetail,
  OrderPay,
  OrderPayResult,
  HotRecommendList,
  HotCourse,
  ExpertList,
  ChannelList,
  ExpertDetail,
  Login,
  InvoiceOrder,
} as any
