import { PERM } from './../utils/permits'
export interface IFMenuBase {
  key: string
  title: string
  icon?: string
  component?: string
  query?: string
  requireAuth?: string
  route?: string
  /** 是否登录校验，true不进行校验（访客） */
  login?: boolean
  code?: string
}

export interface IFMenu extends IFMenuBase {
  subs?: IFMenu[]
}

const menus: {
  menus: IFMenu[]
  others: IFMenu[] | []
  [index: string]: any
} = {
  menus: [
    {
      key: '/app/home',
      title: '首页',
      icon: 'home',
      component: 'Dashboard',
      code: '',
      login: false,
    },
    {
      key: '/app/train',
      title: '培训中心',
      icon: '',
      component: 'Training',
      code: '',
      login: false,
    },
    {
      key: '/app/study',
      title: '学习中心',
      icon: '',
      component: 'StudyCenter',
      code: '',
      login: true,
    },
    {
      key: '/app/user',
      title: '个人中心',
      icon: '',
      component: 'UserCenter',
      code: '',
      login: true,
    },
  ],
  others: [
    {
      key: '/app/home/reclist',
      title: '精品推荐',
      icon: 'home',
      component: 'HotRecommendList',
      code: '',
      login: false,
    },
    {
      key: '/app/home/hotlist',
      title: '热门推荐',
      icon: 'home',
      component: 'HotCourse',
      code: '',
      login: false,
    },
    {
      key: '/app/home/expertlist',
      title: '专家列表',
      icon: 'home',
      component: 'ExpertList',
      code: '',
      login: false,
    },
    {
      key: '/app/home/channelList',
      title: '直播列表',
      icon: 'home',
      component: 'ChannelList',
      code: '',
      login: false,
    },

    {
      key: '/app/home/channelDetail',
      title: '直播详情',
      icon: 'home',
      component: 'ChannelDetail',
      code: '',
      login: false,
    },
    {
      key: '/app/train/list',
      title: '培训列表',
      icon: '',
      component: 'TrainList',
      code: '',
      login: false,
    },
    {
      key: '/app/train/list/course',
      title: '课程详情',
      icon: '',
      component: 'CourseDetail',
      code: '',
      login: false,
    },
    {
      key: '/app/train/list/plan',
      title: '计划详情',
      icon: '',
      component: 'PlanDetail',
      code: '',
      login: false,
    },

    {
      key: '/app/train/list/train',
      title: '培训详情',
      icon: '',
      component: 'TrainDetail',
      code: '',
      login: false,
    },
    {
      key: '/app/study/course',
      title: '课程详情',
      icon: '',
      component: 'CourseDetail',
      code: '',
      login: false,
    },

    {
      key: '/app/study/plan',
      title: '计划详情',
      icon: '',
      component: 'PlanDetail',
      code: '',
      login: false,
    },

    {
      key: '/app/study/train',
      title: '培训详情',
      icon: '',
      component: 'TrainDetail',
      code: '',
      login: false,
    },
    {
      key: '/app/train/course',
      title: '课程详情',
      icon: '',
      component: 'CourseDetail',
      code: '',
      login: false,
    },
    {
      key: '/app/train/plan',
      title: '计划详情',
      icon: '',
      component: 'PlanDetail',
      code: '',
      login: false,
    },

    {
      key: '/app/train/train',
      title: '培训详情',
      icon: '',
      component: 'TrainDetail',
      code: '',
      login: false,
    },
    {
      key: '/app/train/orderConfirm',
      title: '报名',
      icon: '',
      component: 'OrderConfirm',
      code: '',
      login: true,
    },
    {
      key: '/app/train/orderPay',
      title: '订单确认',
      icon: '',
      component: 'OrderPay',
      code: '',
      login: true,
    },

    {
      key: '/app/train/orderPayResult',
      title: '订单支付',
      icon: '',
      component: 'OrderPayResult',
      code: '',
      login: true,
    },
    {
      key: '/app/user/orderDetail',
      title: '订单详情',
      icon: '',
      component: 'OrderDetail',
      code: '',
      login: true,
    },
    {
      key: '/app/home/expert',
      title: '专家详情',
      icon: '',
      component: 'ExpertDetail',
      code: '',
      login: false,
    },
    {
      key: '/app/user/invoiceOrder',
      title: '订单详情',
      icon: '',
      component: 'InvoiceOrder',
      code: '',
      login: true,
    },
    {
      key: '/app/train/pdf',
      title: 'PDF',
      icon: '',
      component: 'WZPdf',
      code: '',
      login: true,
    }
  ], // 非菜单相关路由
}

export default menus
