import * as React from 'react'
import { Upload, Icon, message } from 'antd'
import { BASE_URL, IMAGE_SERVER } from './../../service/config'

export interface IUploaderProps {
  handleUploadFileChange?: any
  defaultImg?: any
  limit?: number
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('只能上传 JPG/PNG 的图片!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('图片不能超过 2MB!')
  }
  return isJpgOrPng && isLt2M
}

export default class Uploader extends React.Component<IUploaderProps> {
  state = {
    loading: false,
    imageUrl: '',
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.defaultImg != this.props.defaultImg) {
      this.setState({ imageUrl: IMAGE_SERVER + nextProps.defaultImg })
    }
  }
  handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setTimeout(() => {
          this.setState({
            imageUrl,
            loading: false,
          })
        }, 0)
      )
      if (this.props.handleUploadFileChange) {
        let fileList: any[] = []
        info.fileList.forEach((item: any) => {
          if (item.response && item.response.msgStatsNum) {
            if (!this.props.limit || this.props.limit == 1) {
              fileList = [item.response.rows[0]]
            } else {
              fileList.push(item.response.rows[0])
            }
          }
        })
        this.props.handleUploadFileChange(fileList)
      }
    }
  }
  public render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    )
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={BASE_URL + 'communalcs/upload'}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {this.state.imageUrl ? (
          <img src={this.state.imageUrl} alt="" style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </Upload>
    )
  }
}
