import React from 'react'
import { Modal, Button, Spin } from 'antd'
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
import { IMAGE_SERVER } from '../../service/config'

export default class WZPdf extends React.Component {
  constructor(props) {
    super(props)
    this.state = { pdfh5: null, loading: false, url: props.query.url }
  }

  componentDidMount() {
    console.log(this.props)
    this.setState({
      loading: true,
    })
    setTimeout(() => {
      const pdfh5 = new Pdfh5('#pdfdemo', {
        pdfurl: IMAGE_SERVER + this.state.url,
        fullscreen: true
      })
      this.setState({
        pdfh5: pdfh5,
      })
      //监听完成事件
      pdfh5.on('ready', (status, msg, time) => {
        this.setState({
          loading: false,
        })
      })
    }, 2000)

  }

  render() {
    return (
      <div class="container">
        <Spin spinning={this.state.loading}>
          <div id="pdfdemo" ></div>
        </Spin>
      </div>
    )
  }
}
