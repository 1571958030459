import * as React from 'react'
import { Form, Input, Button, Select, Modal, message, Radio, DatePicker, InputNumber } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { invoiceSave, invoiceOne, invoiceCompAdd, invoiceComp } from '../../service/index'
import * as _ from 'lodash'

export type IInvoiceAddProps = {
  onSuccess: () => void
  invoiceId?: any
  orderIds?: any
  orderNums?: any
  buttonTitle: string
  icon: string
  invoiceAmount?: any
  editable: boolean
} & FormComponentProps

type IInvoiceAddStates = {
  editDialogVisible: boolean
  orderNums: any
}

const { Option } = Select

class InvoiceAdd extends React.Component<IInvoiceAddProps, IInvoiceAddStates> {
  constructor(props: any) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.state = {
      editDialogVisible: false,
      orderNums: props.orderNums,
    }
  }
  componentDidMount() {}

  componentWillReceiveProps(nextProps: Readonly<IInvoiceAddProps>, nextContext: any): void {
    if (nextProps.orderIds != this.props.orderIds) {
      this.props.form.setFieldsValue({ totalAmount: nextProps.invoiceAmount })
      this.setState({
        orderNums: nextProps.orderNums,
      })
    }
  }

  getInvoiceComp = async (v: any) => {
    const res = await invoiceComp({})
    if (res.success) {
      if (res.data.length > 0) {
        const invoice = res.data[res.data.length - 1]
        if (invoice) {
          if (v == 1) {
            this.props.form.setFieldsValue(
              _.pick(invoice, [
                'compAdress',
                'compBankAccount',
                'compBankName',
                'compName',
                'compPhone',
                'opertime',
                'regtime',
                'taxNum',
              ])
            )
          } else {
            this.props.form.setFieldsValue(_.pick(invoice, ['compName', 'compPhone']))
          }
        }
      }
    }
  }

  getInvoice = async () => {
    if (this.props.invoiceId) {
      const res = await invoiceOne(this.props.invoiceId)
      if (res.success) {
        const invoice = res.data[0]
        if (invoice) {
          setTimeout(() => {
            this.setState({
              ...invoice,
              orderNums: invoice.orderNum,
            })
          }, 0)
          this.props.form.setFieldsValue({
            ...invoice,
          })
        }
      }
    }
  }

  handleOk = (e: any) => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
          invoiceId: this.props.invoiceId,
          orderId: this.props.orderIds,
          orderNum: this.props.orderNums,
        }
        const res = await invoiceSave(params)
        if (res.success) {
          message.success('保存成功')
          this.setState({
            editDialogVisible: false,
          })
          if (this.props.onSuccess) {
            this.props.onSuccess()
          }
        } else {
          message.error(res.msg || '保存失败')
        }
      }
    })
  }

  handleSaveComp = (e: any) => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        }
        const res = await invoiceCompAdd(params)
        if (res.success) {
          message.success('保存成功')
          this.setState({
            editDialogVisible: false,
          })
          if (this.props.onSuccess) {
            this.props.onSuccess()
          }
        } else {
          message.error(res.msg || '保存失败')
        }
      }
    })
  }

  handleCancel = (e: any) => {
    this.setState({
      editDialogVisible: false,
    })
  }

  async addComp() {
    const form = this.props.form!
    const params = {
      ..._.pick(form.getFieldsValue(), [
        'compAdress',
        'compBankAccount',
        'compBankName',
        'compName',
        'compPhone',
        'opertime',
        'regtime',
        'taxNum',
      ]),
    }

    const res = await invoiceCompAdd(params)
    console.log(res)
    if (res.success) {
      message.success('添加成功')
    } else {
      message.error(res.msg)
    }
  }

  public render() {
    const { getFieldDecorator } = this.props.form!

    const receiveType = this.props.form.getFieldValue('receiveType')
    const titleType = this.props.form.getFieldValue('titleType')
    return (
      <div>
        <Modal
          width="40%"
          title={this.props.invoiceId ? '编辑发票' : '添加发票'}
          visible={this.state.editDialogVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form layout="horizontal" style={{ marginBottom: 16 }}>
            <Form.Item label="订单编号">{this.state.orderNums}</Form.Item>
            <Form.Item label="发票类型">
              {getFieldDecorator('invoiceType', {
                rules: [{ required: true, message: '请选择发票类型!' }],
              })(
                <Select style={{ width: 180 }} allowClear disabled={!this.props.editable}>
                  <Option key={1} value={1}>
                    增值税专用发票
                  </Option>
                  <Option key={2} value={2}>
                    增值税普通发票（电子）
                  </Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="抬头类型">
              {getFieldDecorator('titleType', {
                rules: [{ required: true, message: '请选择抬头类型!' }],
              })(
                <Select
                  style={{ width: 180 }}
                  allowClear
                  disabled={!this.props.editable}
                  onChange={(v) => {
                    if (v == 1) {
                      this.getInvoiceComp(v)
                    } else {
                      this.props.form.setFieldsValue({
                        compName: '',
                        compPhone: '',
                      })
                    }
                  }}
                >
                  <Option key={1} value={1}>
                    企业单位
                  </Option>
                  <Option key={2} value={2}>
                    个人/非企业单位
                  </Option>
                </Select>
              )}
            </Form.Item>
            {titleType == 1 && (
              <Form.Item label="税号">
                {getFieldDecorator('taxNum', {
                  rules: [{ required: true, message: '请输入税号!' }],
                })(<Input placeholder="税号" disabled={!this.props.editable} />)}
              </Form.Item>
            )}

            <Form.Item label={titleType == 1 ? '企业名称' : '姓名'}>
              {getFieldDecorator('compName', {
                rules: [{ required: true, message: '请输入企业名称!' }],
              })(<Input placeholder="企业名称" disabled={!this.props.editable} width="400px" />)}
              {titleType == 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.addComp()
                  }}
                >
                  添加常用
                </Button>
              )}
            </Form.Item>
            {titleType == 1 && (
              <Form.Item label="公司地址">
                {getFieldDecorator('compAdress', {
                  rules: [{ required: true, message: '请输入公司地址!' }],
                })(<Input placeholder="公司地址" disabled={!this.props.editable} />)}
              </Form.Item>
            )}
            <Form.Item label={titleType == 1 ? '公司电话' : '电话'}>
              {getFieldDecorator('compPhone', {
                rules: [{ required: true, message: '请输入公司电话!' }],
              })(<Input placeholder="公司电话" disabled={!this.props.editable} />)}
            </Form.Item>
            {titleType == 1 && (
              <Form.Item label="公司开户行">
                {getFieldDecorator('compBankName', {
                  rules: [{ required: true, message: '请输入公司开户行!' }],
                })(<Input placeholder="公司开户行" disabled={!this.props.editable} />)}
              </Form.Item>
            )}
            {titleType == 1 && (
              <Form.Item label="公司开户账号">
                {getFieldDecorator('compBankAccount', {
                  rules: [{ required: true, message: '请输入公司开户账号!' }],
                })(<Input placeholder="公司开户账号" disabled={!this.props.editable} />)}
              </Form.Item>
            )}

            <Form.Item label="合计金额">
              {getFieldDecorator('totalAmount', {
                rules: [{ required: true, message: '请输入公司开户账号!' }],
              })(<InputNumber disabled />)}
            </Form.Item>
            <Form.Item label="发票内容">
              {getFieldDecorator('invoiceContent', {
                rules: [{ required: true, message: '请输入发票内容!' }],
              })(<Input placeholder="发票内容" disabled={!this.props.editable} />)}
            </Form.Item>
            <Form.Item label="开票方">
              {getFieldDecorator('issuingUnit', {
                rules: [{ required: true, message: '请输入开票方!' }],
              })(<Input placeholder="开票方" disabled={!this.props.editable} />)}
            </Form.Item>
            <Form.Item label="接收方式">
              {getFieldDecorator('receiveType', {
                rules: [{ required: true, message: '请选择接收方式!' }],
              })(
                <Select style={{ width: 120 }} allowClear disabled={!this.props.editable}>
                  <Option key={1} value={1}>
                    快递
                  </Option>
                  <Option key={2} value={2}>
                    邮件
                  </Option>
                </Select>
              )}
            </Form.Item>
            {receiveType == 1 && (
              <Form.Item label="收件人">
                {getFieldDecorator('receivePerson', {
                  rules: [{ required: true, message: '请输入收件人!' }],
                })(<Input placeholder="收件人" disabled={!this.props.editable} />)}
              </Form.Item>
            )}
            {receiveType == 1 && (
              <Form.Item label="收件电话">
                {getFieldDecorator('receivePhone', {
                  rules: [{ required: true, message: '请输入收件电话!' }],
                })(<Input placeholder="收件电话" disabled={!this.props.editable} />)}
              </Form.Item>
            )}
            {receiveType == 1 && (
              <Form.Item label="收件地址">
                {getFieldDecorator('receiveAddress', {
                  rules: [{ required: true, message: '请输入收件地址!' }],
                })(<Input placeholder="收件地址" disabled={!this.props.editable} />)}
              </Form.Item>
            )}
            {receiveType == 2 && (
              <Form.Item label="电子邮箱">
                {getFieldDecorator('receiveEmail', {
                  rules: [{ required: true, message: '请输入电子邮箱!' }],
                })(<Input placeholder="电子邮箱" disabled={!this.props.editable} />)}
              </Form.Item>
            )}
            <Form.Item label="备注">
              {getFieldDecorator('remark', {
                rules: [{ required: false, message: '请输入备注!' }],
              })(<Input placeholder="备注" disabled={!this.props.editable} />)}
            </Form.Item>
          </Form>
        </Modal>
        <Button
          icon={this.props.icon}
          type="primary"
          onClick={() => {
            setTimeout(async () => {
              this.setState({
                editDialogVisible: true,
              })
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<IInvoiceAddProps>()(InvoiceAdd)
