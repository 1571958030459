import * as React from 'react'
import { Cascader } from 'antd'
import { getGlobalRegion } from './../../service/index'

export interface IRegionCascaderProps {
  maxLevel?: any
  regiProvinId?: any
  provinName?: any
  regiCityId?: any
  cityName?: any
  regiCountrId?: any
  countrName?: any
  onChange: (e: any) => void
}

type IRegionCascaderStates = {
  options: any
  defaultValue?: any
}

export default class RegionCascader extends React.Component<
  IRegionCascaderProps,
  IRegionCascaderStates
> {
  constructor(props: any) {
    super(props)
    this.state = {
      options: [],
    }
    this.onChange = this.onChange.bind(this)
    this.getRegion = this.getRegion.bind(this)
  }

  async componentDidMount() {
    this.initRegion()
    this.setState({
      defaultValue: [this.props.regiProvinId, this.props.regiCityId, this.props.regiCountrId],
    })
  }

  componentDidUpdate(props: any) {
    if (
      props.regiProvinId != this.props.regiProvinId ||
      props.regiCityId != this.props.regiCityId ||
      props.regiCountrId != this.props.regiCountrId
    ) {
      this.initRegion()
      this.setState({
        defaultValue: [this.props.regiProvinId, this.props.regiCityId, this.props.regiCountrId],
      })
    }
  }

  initRegion = async () => {
    const options = await this.getRegion('', 1)
    if (this.props.regiProvinId) {
      const seletOption = options.find((item: any) => item.value == this.props.regiProvinId)
      if (seletOption) {
        seletOption.children = await this.getRegion(this.props.regiProvinId, 2)
      }
    }
    if (this.props.regiCityId) {
      const province = options.find((item: any) => item.value == this.props.regiProvinId)
      const seletOption = province.children.find((item: any) => item.value == this.props.regiCityId)
      if (this.props.maxLevel > 2 && seletOption) {
        seletOption.children = await this.getRegion(this.props.regiCityId, 3)
      }
    }
    setTimeout(() => {
      this.setState({
        options: options,
      })
    }, 0)
  }

  getRegion = async (pId: any, level: any) => {
    const params = {
      id: '',
      pId: pId,
      level: level,
    }
    const res = await getGlobalRegion(params)
    if (res.success) {
      const options = res.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
          level: item.level,
          isLeaf: (this.props.maxLevel && this.props.maxLevel == level) || level == 3,
        }
      })
      return options
    }
    return []
  }

  onChange = (value: any, selectedOptions: any) => {
    this.props.onChange(selectedOptions)
  }

  loadData = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    if (this.props.maxLevel && targetOption.level >= this.props.maxLevel) {
      return
    }
    targetOption.loading = true

    const options = await this.getRegion(targetOption.value, targetOption.level + 1)

    targetOption.loading = false
    targetOption.children = options
    setTimeout(() => {
      this.setState({
        options: [...this.state.options],
      })
    }, 0)
  }

  public render() {
    return (
      <Cascader
        key={`${Math.floor(Math.random() * 1000)}-min`}
        defaultValue={this.state.defaultValue}
        options={this.state.options}
        loadData={this.loadData}
        onChange={this.onChange}
        changeOnSelect
      />
    )
  }
}
