import * as React from 'react'
import Breadcrumb from '../Breadcrumb'
import { Input, InputNumber, Divider, Form, Checkbox, Button, message } from 'antd'
import { createOrder, trainOne, planOne, courseOne } from '../../service/index'
import { IMAGE_SERVER, TITLE } from '../../service/config'
import { FormComponentProps } from 'antd/lib/form'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import umbrella from 'umbrella-storage'

export interface IOrderConfirmProps extends FormComponentProps, RouteComponentProps<any> {
  type: any
  prodId: any
}
interface IOrderConfirmStates {
  buyNum: any
  totalPrice: any
  id: any
  type: any
  order: any
  stu: any
  agreeProtocol: any
}

class OrderConfirm extends React.Component<IOrderConfirmProps, IOrderConfirmStates> {
  constructor(props: any) {
    super(props)
    const { id, type } = props.query
    let stu = {}
    this.confirmOrder = this.confirmOrder.bind(this)
    let user = umbrella.getLocalStorage('user')
    if (user && user.studentJson) {
      stu = user.studentJson[0]
    } else {
      message.warn('您还未登录！')
      return
    }
    this.state = {
      order: {},
      buyNum: 0,
      totalPrice: 0,
      id,
      type,
      stu: stu,
      agreeProtocol: false,
    }
  }
  componentDidMount() {
    const { type, stu } = this.state
    if (type === 'train') {
      this.getTrain()
    } else if (type === 'course') {
      this.getCourse()
    } else if (type === 'plan') {
      this.getPlan()
    }

    this.props.form.setFieldsValue({
      compName: stu.compName,
      linkPerson: stu.stuName,
      linkPhone: stu.phone,
    })
  }

  async getTrain() {
    const params = { itemId: this.state.id }
    const res = await trainOne(params)

    if (res.success) {
      const train = res.data[0]
      const price = train.prefePrice || train.price
      const order = {
        ...train,
        prefePrice: price,
      }
      this.props.form.setFieldsValue({ ...order, enrollCount: 1 })
      this.setState({ order: order })
      this.calcTotalPrice(1)
    }
  }

  async getPlan() {
    const params = { planId: this.state.id }
    const res = await planOne(params)

    if (res.success) {
      const plan = res.data[0]
      const price = plan.prefePrice || plan.price
      const order = {
        ...plan,
        prefePrice: price,
      }
      this.props.form.setFieldsValue({ ...order, enrollCount: 1 })
      this.setState({ order: order })
      this.calcTotalPrice(1)
    }
  }

  async getCourse() {
    const params = { courseId: this.state.id }
    const res = await courseOne(params)

    if (res.success) {
      const course = res.data[0]
      const price = course.prefePrice || course.price
      const order = {
        ...course,
        prefePrice: price,
      }
      this.props.form.setFieldsValue({ ...order, enrollCount: 1 })
      this.setState({ order: order })
      this.calcTotalPrice(1)
    }
  }

  async confirmOrder() {
    const values = this.props.form.getFieldsValue()
    let orderType = 0
    switch (this.state.type) {
      case 'train':
        orderType = 2
        break
      case 'course':
        orderType = 1
        break
      case 'plan':
        orderType = 3
        break
      default:
        break
    }
    const { stu } = this.state
    const selfStu = {
      stuName: stu.stuName,
      compName: stu.compName,
      phone: stu.phone,
    }
    const params = {
      orderType: orderType,
      orderTitle: this.state.order.title,
      orderTypeId: this.state.id,
      ...values,
      unitPrice: this.state.order.prefePrice,
      totalAmount: this.state.totalPrice,
      orderStuJson: JSON.stringify([selfStu]),
    }
    const res = await createOrder(params)

    if (res.success) {
      const order = res.data[0]
      const orderId = order.orderId
      this.props.history.push('/app/train/orderPay?orderId=' + orderId)
    } else {
      message.error(res.msg || '报名失败')
    }
  }

  calcTotalPrice(value: any) {
    setTimeout(() => {
      const { order } = this.state
      this.setState({
        buyNum: value,
        totalPrice: value * order.prefePrice,
      })
    }, 0)
  }

  public render() {
    const { order } = this.state
    const { getFieldDecorator } = this.props.form!
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }
    return (
      <div>
        <div className="app_layout_main">
          <Breadcrumb />
          <div className="course_detail_body">
            <div className="flex">
              <div className="course-box">
                <img className="img" src={IMAGE_SERVER + order.coverDirectURL} alt="" />
              </div>
              <div className="course-box ml-m">
                <div className="title">{order.title}</div>
                <div className="detail">
                  <div>
                    <span>
                      {order.enrollStartDate} / {order.enrollEndDate}
                    </span>
                    {order.enrollSub && (
                      <span className="ml-m">
                        名额剩余：{order.enrollSub == '-1' ? '不限' : order.enrollSub}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div>
              <Form
                {...formItemLayout}
                style={{ marginBottom: 16, width: '50%' }}
                title="联络人信息"
              >
                <Form.Item label="单价">
                  <span className="price text">￥{order.prefePrice}</span>
                </Form.Item>
                <Form.Item label="报名人数">
                  {getFieldDecorator('enrollCount')(
                    <InputNumber
                      min={1}
                      max={10}
                      onChange={(value: any) => {
                        this.calcTotalPrice(value)
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item label="总价">
                  <span className="price text">￥{this.state.totalPrice}</span>
                </Form.Item>
                <Divider>联络人信息</Divider>
                <Form.Item label="单位名称">
                  {getFieldDecorator('compName')(<Input placeholder="单位名称" allowClear />)}
                </Form.Item>
                <Form.Item label="联系人">
                  {getFieldDecorator('linkPerson')(<Input placeholder="联系人" allowClear />)}
                </Form.Item>
                <Form.Item label="联系电话">
                  {getFieldDecorator('linkPhone')(<Input placeholder="联系电话" allowClear />)}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  {getFieldDecorator('agree')(
                    <Checkbox
                      onChange={(e) => {
                        console.log(e)
                        const value = e.target.checked
                        this.setState({
                          agreeProtocol: value,
                        })
                      }}
                    >
                      同意
                      <a href="assets/orderProtocol.html" target="_blank">
                        &nbsp; {TITLE}协议
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    type="primary"
                    disabled={!this.state.agreeProtocol}
                    onClick={() => {
                      this.confirmOrder()
                    }}
                  >
                    去付款
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Form.create<IOrderConfirmProps>()(withRouter(OrderConfirm))
