export type TreeTranslateObj = {
  data: any
  titleAttr: any
  idAttr: any
  pidAttr: any
  otherAttr: any

  resultTitleAttr: any
  resultIdAttr: any
  resultChildAttr: any
  resultOtherAttr: any
}

export default function TreeData(transObj: TreeTranslateObj) {
  const originData = {
    ...transObj,
    data: transObj.data,
    titleAttr: transObj.titleAttr || 'name',
    idAttr: transObj.idAttr || '',
    pidAttr: transObj.pidAttr || 'pid',
    otherAttr: '',

    resultTitleAttr: transObj.resultTitleAttr || 'name',
    resultIdAttr: transObj.resultIdAttr || 'id',
    resultChildAttr: transObj.resultChildAttr || 'children',
    resultOtherAttr: '',
  }

  /**
   * 树形数据转换
   * @param {*} data
   * @param {*} id
   * @param {*} pid
   */
  const treeDataTranslate = (data: any, pid: any) => {
    var result: any = []
    var temp: any
    for (var i = 0; i < data.length; i++) {
      const dataItem = data[i]
      if (
        dataItem[originData.pidAttr] == pid ||
        (!pid && !dataItem[originData.pidAttr]) ||
        (dataItem[originData.pidAttr] == -1 && !pid)
      ) {
        const name = dataItem[originData.titleAttr]
        const id = dataItem[originData.idAttr]
        var obj: any = {}
        obj[originData.resultTitleAttr] = name
        obj[originData.resultIdAttr] = id
        if (originData.otherAttr && originData.resultOtherAttr) {
          obj[originData.otherAttr] = dataItem[originData.resultOtherAttr]
        }
        temp = treeDataTranslate(data, id)

        if (temp.length > 0) {
          obj[originData.resultChildAttr] = temp
        } else {
          obj[originData.resultChildAttr] = []
        }
        result.push(obj)
      }
    }
    return result
  }

  const data = treeDataTranslate(originData.data, null)
  return data
}
