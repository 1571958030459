import * as React from 'react'
import ApplyStuEdit from './ApplyStuEdit'
import DocUploader from '../widget/DocUploader'
import { orderStuAdd, orderStudentImp } from '../../service/index'
import { Modal, Button, Table, message, Descriptions } from 'antd'
import { IMAGE_SERVER } from './../../service/config'

export interface IOrderStuAddProps {
  order: any
  btnType: any
  onSuccess: () => void
}
interface IOrderStuAddStates {
  applyStuList: any[]
  loading: boolean
  visible: boolean
}
const { Column } = Table
export default class OrderStuAdd extends React.Component<IOrderStuAddProps, IOrderStuAddStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      applyStuList: [...props.order.orderStuJson],
      loading: false,
      visible: false,
    }
  }
  handleUploadFileChange = async (e: any) => {
    const res = await orderStudentImp(e)
    if (res.success) {
      message.success('导入成功')
    } else {
      let str = res.msg + '，'
      res.data.forEach((item: any) => {
        str += `(第${item.rowNum}行, 【${item.column}】 列)\n`
      })
      message.warn(str)
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = async (e: any) => {
    this.setState({
      visible: false,
    })
    const params = {
      orderStuJson: JSON.stringify(this.state.applyStuList),
      orderId: this.props.order.orderId,
    }
    const res = await orderStuAdd(params)
    if (res.success) {
      message.success('学员添加成功')
      this.props.onSuccess()
    } else {
      message.error('学员添加失败')
    }
  }

  handleCancel = (e: any) => {
    this.setState({
      visible: false,
    })
  }

  public render() {
    return (
      <div>
        <Modal
          title="添加学员"
          width="60%"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="mb-m">
            <Descriptions size="small" column={3}>
              <Descriptions.Item label="订单号" span={1}>
                {this.props.order.orderNum}
              </Descriptions.Item>
              <Descriptions.Item label="标题" span={1}>
                {this.props.order.orderTitle}
              </Descriptions.Item>
              <Descriptions.Item label="学员数" span={1}>
                {this.state.applyStuList.length || 0} / {this.props.order.enrollCount || 0}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="flex mb-m">
            <ApplyStuEdit
              type="add"
              onCourseChange={(stu: any) => {
                const applyStuList: any[] = this.state.applyStuList
                if (this.props.order.enrollCount <= applyStuList.length) {
                  message.warn('已超出报名人数限制')
                  return
                }
                applyStuList.push(stu)
                this.setState({
                  applyStuList: applyStuList,
                })
              }}
            />
            <DocUploader
              buttonTitle="导入学员"
              supportTypes="Excel"
              accept=".xls"
              handleUploadFileChange={this.handleUploadFileChange}
            />
            <Button
              type="link"
              onClick={() => {
                window.open(IMAGE_SERVER + '/share/orderStuTemplate.xlsx')
              }}
            >
              下载模板
            </Button>
          </div>
          <Table
            rowKey="itemId"
            size="small"
            loading={this.state.loading}
            dataSource={this.state.applyStuList}
          >
            <Column
              title="序号"
              dataIndex="name"
              key="name"
              render={(name: any, row: any, index) => index + 1}
            />
            <Column
              title="姓名"
              dataIndex="stuName"
              key="stuName"
              render={(stuName: any) => stuName}
            />
            <Column
              title="单位名称"
              dataIndex="compName"
              key="compName"
              render={(compName: any) => compName}
            />
            <Column title="电话" dataIndex="phone" key="phone" render={(phone: any) => phone} />
            <Column
              title="操作"
              dataIndex="itemId"
              key="itemId"
              render={(itemId: any, row: any, index: number) => (
                <div className="flex">
                  <ApplyStuEdit
                    type="edit"
                    item={row}
                    onCourseChange={(stu: any) => {
                      const applyStuList: any[] = this.state.applyStuList
                      const oldStu = applyStuList[index]
                      applyStuList[index] = { ...oldStu, ...stu }
                      this.setState({
                        applyStuList: applyStuList,
                      })
                    }}
                  />
                  <Button
                    type="link"
                    color="red"
                    onClick={() => {
                      const { applyStuList } = this.state
                      applyStuList.splice(index, 1)
                      this.setState({
                        applyStuList: applyStuList,
                      })
                    }}
                  >
                    删除
                  </Button>
                </div>
              )}
            />
          </Table>
        </Modal>
        <Button
          type={this.props.btnType || 'link'}
          onClick={(e: any) => {
            this.setState({ visible: true })
          }}
        >
          添加学员
        </Button>
      </div>
    )
  }
}
