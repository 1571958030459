import * as React from 'react'
import { Modal, Button, Input, Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

export interface IApplyStuEditProps extends FormComponentProps {
  type: string
  item?: any
  onCourseChange: (course: any) => void
}

class ApplyStuEdit extends React.Component<IApplyStuEditProps> {
  // eslint-disable-next-line react/sort-comp
  componentDidMount() {
    if (this.props.item) {
      this.props.form.setFieldsValue({
        ...this.props.item,
      })
    }
  }

  state = {
    visible: false,
  }

  handleOk = (_e: any) => {
    const values = this.props.form.getFieldsValue()
    const stu = {
      ...this.props.item,
      ...values,
    }
    this.props.onCourseChange(stu)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (_e: any) => {
    this.setState({
      visible: false,
    })
  }
  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div>
        <Modal
          title={this.props.type == 'add' ? '新增学员' : '编辑学员'}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form style={{ marginBottom: 16 }}>
            <Form.Item label="姓名">
              {getFieldDecorator('stuName')(<Input placeholder="姓名" allowClear />)}
            </Form.Item>
            <Form.Item label="手机号码">
              {getFieldDecorator('phone')(<Input placeholder="手机号码" allowClear />)}
            </Form.Item>
            <Form.Item label="单位名称">
              {getFieldDecorator('compName')(<Input placeholder="单位名称" allowClear />)}
            </Form.Item>
          </Form>
        </Modal>
        <Button
          className="mr-m"
          type={this.props.type == 'add' ? 'primary' : 'link'}
          onClick={() => {
            this.setState({
              visible: true,
            })
          }}
        >
          {this.props.type === 'add' && '新增'}
          {this.props.type === 'edit' && '编辑'}
        </Button>
      </div>
    )
  }
}

export default Form.create<IApplyStuEditProps>()(ApplyStuEdit)
