import * as React from 'react'
import { Card, Empty } from 'antd'
import { Link } from 'react-router-dom'
import { courseList, planList, trainList } from './../../service/index'
import { IMAGE_SERVER } from '../../service/config'

export interface ITrainingProps {}
interface ITrainingStates {
  courseList: any[]
  trainList: any[]
  planList: any[]
}

const ROW_COUNT = 5

export default class Training extends React.Component<ITrainingProps, ITrainingStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      courseList: [],
      trainList: [],
      planList: [],
    }
  }
  componentDidMount() {
    this.getPlanList()
    this.getTrainList()
    this.getCourseList()
  }

  async getPlanList() {
    const params = {}
    const res = await planList(params)
    if (res.success) {
      const planJson = res.data
      this.setState({
        planList: planJson.slice(0, ROW_COUNT),
      })
    }
  }

  async getTrainList() {
    const params = {}
    const res = await trainList(params)
    if (res.success) {
      const trainJson = res.data
      this.setState({
        trainList: trainJson.slice(0, ROW_COUNT),
      })
    }
  }

  async getCourseList() {
    const params = {}
    const res = await courseList(params)
    if (res.success) {
      const courseJson = res.data
      this.setState({
        courseList: courseJson.slice(0, ROW_COUNT),
      })
    }
  }

  public render() {
    return (
      <div className="app_layout_main">
        {
          this.state.planList.length > 0 && 
          <Card
            title="培训计划"
            className="mt-m"
            bordered={false}
            bodyStyle={{ padding: 0 }}
            headStyle={{ padding: 0 }}
            extra={
              <Link to={'/app/train/list?type=plan'} color="gray">
                更多&gt;
              </Link>
            }
          >
            {this.state.planList.length === 0 && (
              <div className="flex-h-center w-h-100">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{}}
                  description={<span>目前没有计划哦</span>}
                ></Empty>
              </div>
            )}
            <div className="flex-wrapper">
              {this.state.planList &&
                this.state.planList.map((item: any) => (
                  <Link to={'/app/train/plan?id=' + item.planId} color="gray">
                    <div className="course-box mt-m mr-m" title={item.title}>
                      <img className="img" src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                      <div className="title">{item.title}</div>
                    </div>
                  </Link>
                ))}
            </div>
          </Card>
        }
        {
          this.state.trainList.length > 0 && 
          <Card
            title="培训项目"
            className="mt-m"
            bodyStyle={{ padding: 0 }}
            headStyle={{ padding: 0 }}
            bordered={false}
            extra={
              <Link to={'/app/train/list?type=train'} color="#999">
                更多&gt;
              </Link>
            }
          >
            {this.state.trainList.length === 0 && (
              <div className="flex-h-center w-h-100">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{}}
                  description={<span>目前没有项目哦</span>}
                ></Empty>
              </div>
            )}
            <div className="flex-wrapper">
              {this.state.trainList &&
                this.state.trainList.map((item: any) => (
                  <Link to={'/app/train/train?id=' + item.itemId} color="gray">
                    <div className="course-box mt-m mr-m" title={item.title}>
                      <img className="img" src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                      <div className="title">{item.title}</div>
                    </div>
                  </Link>
                ))}
            </div>
          </Card>
        }
        <Card
          title="培训课程"
          className="mt-m"
          bodyStyle={{ padding: 0 }}
          headStyle={{ padding: 0 }}
          bordered={false}
          extra={
            <Link to={'/app/train/list?type=course'} color="gray">
              更多&gt;
            </Link>
          }
        >
          {this.state.courseList.length === 0 && (
            <div className="flex-h-center w-h-100">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{}}
                description={<span>目前没有课程哦</span>}
              ></Empty>
            </div>
          )}
          <div className="flex-wrapper">
            {this.state.courseList &&
              this.state.courseList.map((item: any) => (
                <Link to={'/app/train/course?id=' + item.courseId} color="gray">
                  <div className="course-box mt-m mr-m" title={item.title}>
                    <img className="img" src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                    <div className="title">{item.title}</div>
                  </div>
                </Link>
              ))}
          </div>
        </Card>
      </div>
    )
  }
}
