import * as React from 'react'
import { Input, Tag, Divider, Pagination, Form } from 'antd'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import { courseList, planList, trainList, categoryMore } from './../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connectAlita } from 'redux-alita'
import FormItem from 'antd/lib/form/FormItem'
import { FormComponentProps } from 'antd/lib/form'
import { getQueryVariable } from './../../utils'
import TreeData, { TreeTranslateObj } from '../../utils/TreeData'
import { IMAGE_SERVER } from '../../service/config'

const { CheckableTag } = Tag
const { Search } = Input

export interface ITrainListProps extends FormComponentProps, RouteComponentProps<any> {}

interface ITrainListStates {
  type: String
  querySearchText: any
  searchText: any
  courseList: any[]
  trainList: any[]
  planList: any[]
  categoryList: any[]
  categorySelIds: any[]
  page: number
  pageSize: number
  total: number
  currentSelectYear: any
  yearList: any[]
}

class TrainList extends React.Component<ITrainListProps, ITrainListStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      type: props.query.type,
      querySearchText: '',
      searchText: '',
      courseList: [],
      trainList: [],
      planList: [],
      categoryList: [],
      categorySelIds: [props.query.categoryId || ''],
      page: 1,
      pageSize: 10,
      total: 0,
      currentSelectYear: '',
      yearList: [],
    }
  }

  componentDidMount() {
    this.initData()
    this.getCategoryList()
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.query.searchText !== this.state.querySearchText ||
      nextProps.query.type !== this.state.type
    ) {
      setTimeout(() => {
        this.setState({
          querySearchText: nextProps.query.searchText,
          type: nextProps.query.type,
        })
        this.initData()
      }, 0)
    }
  }

  initData() {
    const searchText = getQueryVariable('searchText', this.props.location.search)
    const type = getQueryVariable('type', this.props.location.search)

    let date = new Date()
    const year = date.getFullYear()

    setTimeout(async () => {
      let yearList = []
      for (let i = 0; i < 5; i++) {
        const element = year - i
        yearList.push(element)
      }

      this.setState({
        yearList: yearList,
        querySearchText: searchText,
        searchText: searchText ? searchText : '',
        type,
      })
      this.getList(this.state.type)
    }, 0)
  }

  async getList(type: String) {
    if (type === 'plan') {
      this.getPlanList()
    } else if (type === 'train') {
      this.getTrainList()
    } else if (type === 'course') {
      this.getCourseList()
    }
  }

  async getPlanList() {
    let categoryId = this.getSelCategoryId()
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      queryYear: this.state.currentSelectYear,
      title: this.state.searchText,
      categoryId: categoryId,
    }
    const res = await planList(params)
    if (res.success) {
      const planJson = res.data
      setTimeout(() => {
        this.setState({
          planList: planJson,
          total: res.total,
        })
      }, 0)
    }
  }

  async getTrainList() {
    let categoryId = this.getSelCategoryId()
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      categoryId: categoryId,
      queryYear: this.state.currentSelectYear,
      title: this.state.searchText,
    }
    const res = await trainList(params)
    if (res.success) {
      const trainJson = res.data
      setTimeout(() => {
        this.setState({
          trainList: trainJson,
          total: res.total,
        })
      }, 0)
    }
  }

  async getCourseList() {
    let categoryId = this.getSelCategoryId()
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      queryYear: this.state.currentSelectYear,
      title: this.state.searchText,
      categoryId: categoryId,
    }
    const res = await courseList(params)
    if (res.success) {
      const courseJson = res.data
      setTimeout(() => {
        this.setState({
          courseList: courseJson,
          total: res.total,
        })
      }, 0)
    }
  }

  getSelCategoryId() {
    const categoryIds = [...this.state.categorySelIds].reverse()
    let categoryId = ''
    for (let index = 0; index < categoryIds.length; index++) {
      categoryId = categoryIds[index]
      if (categoryId) {
        break
      }
    }
    return categoryId
  }

  async getCategoryList() {
    const params = {}
    const res = await categoryMore(params)
    const data = res.data
    const treeProps: TreeTranslateObj = {
      data: data,
      titleAttr: 'categoryName',
      idAttr: 'categoryId',
      pidAttr: 'categoryPId',
      otherAttr: 'categoryPId',
      resultTitleAttr: 'categoryName',
      resultIdAttr: 'categoryId',
      resultChildAttr: 'children',
      resultOtherAttr: 'key',
    }
    const treeData = TreeData(treeProps)
    const { categorySelIds, categoryList } = this.state
    const firstSelCateId = categorySelIds[0]
    categoryList[0] = treeData
    let selCategory = { category: '', children: [] }
    for (let index = 0; index < treeData.length; index++) {
      const element = treeData[index]
      if (element.categoryId == firstSelCateId) {
        selCategory = element
        break
      }
    }
    this.setState({
      categoryList: categoryList,
      categorySelIds: categorySelIds,
    })

    this.onCategoryTagClickChecked(0, selCategory)
  }

  onSearch(value: any) {
    setTimeout(() => {
      this.setState({
        searchText: value,
      })
      this.getList(this.state.type)
    }, 0)
  }

  onCategoryTagClickChecked(index: any, item: any) {
    const { categorySelIds, categoryList } = this.state
    const newCategorySelIds = categorySelIds.slice(0, index + 1)
    const newCategoryList = categoryList.slice(0, index + 1)
    newCategorySelIds[index] = item.categoryId
    newCategoryList[index + 1] = item.children

    setTimeout(() => {
      this.setState({
        categorySelIds: newCategorySelIds,
        categoryList: newCategoryList,
        page: 1,
      })
      this.getList(this.state.type)
    }, 0)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    let title = ''
    const type = getQueryVariable('type', this.props.location.search)
    switch (type) {
      case 'plan':
        title = '计划列表'
        break
      case 'train':
        title = '培训列表'
        break
      case 'course':
        title = '课程列表'
        break
      default:
        break
    }
    return (
      <div className="app_layout_main">
        <BreadcrumbCustom first={title} />
        {!this.state.querySearchText && (
          <div>
            <div className="flex-h-around mt-m">
              <div className="title flex-2">{title}</div>
              <div className="flex-1">
                <Form>
                  <FormItem>
                    {getFieldDecorator('searchText')(
                      <Search
                        placeholder="请输入想要的内容"
                        size="default"
                        style={{ width: '70%' }}
                        onSearch={(value) => this.onSearch(value)}
                      />
                    )}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div className="mt-m">
              <div className="flex">
                <div className="text-label">年份</div>
                <div>
                  <CheckableTag
                    key="all"
                    checked={!this.state.currentSelectYear}
                    onChange={(checked) => {
                      setTimeout(() => {
                        this.setState({
                          page: 1,
                          currentSelectYear: '',
                        })
                        this.getList(this.state.type)
                      }, 0)
                    }}
                  >
                    全部
                  </CheckableTag>
                  {this.state.yearList.map((item: any) => (
                    <CheckableTag
                      key={item}
                      checked={this.state.currentSelectYear == item}
                      onChange={(checked) => {
                        setTimeout(() => {
                          if (checked) {
                            this.setState({
                              currentSelectYear: item,
                            })
                          }
                          this.setState({
                            page: 1,
                          })
                          this.getList(this.state.type)
                        }, 0)
                      }}
                    >
                      {item}
                    </CheckableTag>
                  ))}
                </div>
              </div>

              {this.state.categoryList.map((curCategoryList: any, index: any) => {
                let indexStr = ''
                switch (index) {
                  case 0:
                    indexStr = '一'
                    break
                  case 1:
                    indexStr = '二'
                    break
                  case 2:
                    indexStr = '三'
                    break
                  default:
                    break
                }
                let selectId = this.state.categorySelIds[index]
                return (
                  <div>
                    {curCategoryList && curCategoryList.length > 0 && (
                      <div className="mt-s flex">
                        <div className="text-label">{indexStr}级分类</div>
                        <div className="category-list">
                          <CheckableTag
                            checked={!selectId}
                            onChange={(checked) => {
                              setTimeout(() => {
                                // 清空后面的数组
                              }, 0)

                              this.onCategoryTagClickChecked(index, {
                                categoryId: '',
                                children: [],
                              })
                            }}
                          >
                            全部
                          </CheckableTag>
                          {curCategoryList.map((item: any) => (
                            <CheckableTag
                              checked={selectId == item.categoryId}
                              onChange={(checked) => {
                                setTimeout(() => {
                                  if (checked) {
                                    this.onCategoryTagClickChecked(index, item)
                                  }
                                }, 0)
                              }}
                            >
                              {item.categoryName}
                            </CheckableTag>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <Divider />
          </div>
        )}

        <div className="flex-wrapper">
          {this.state.type === 'plan' &&
            this.state.planList &&
            this.state.planList.map((item: any) => (
              <Link to={'/app/train/list/plan?id=' + item.planId} color="gray">
                <div className="course-box mt-m mr-m">
                  <img className="img" src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                  <div className="title">{item.title}</div>
                </div>
              </Link>
            ))}
          {this.state.type === 'train' &&
            this.state.trainList &&
            this.state.trainList.map((item: any) => (
              <Link to={'/app/train/list/train?id=' + item.itemId} color="gray">
                <div className="course-box mt-m mr-m">
                  <img className="img" src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                  <div className="title">{item.title}</div>
                </div>
              </Link>
            ))}
          {this.state.type === 'course' &&
            this.state.courseList &&
            this.state.courseList.map((item: any) => (
              <Link to={'/app/train/list/course?id=' + item.courseId} color="gray">
                <div className="course-box mt-m mr-m">
                  <img className="img" src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                  <div className="title">{item.title}</div>
                </div>
              </Link>
            ))}
        </div>
        <div className="flex-h-end mt-m">
          <Pagination
            current={this.state.page}
            total={this.state.total}
            pageSize={this.state.pageSize}
            onChange={(page: any) => {
              setTimeout(() => {
                this.setState({
                  page: page,
                })

                this.getList(this.state.type)
              }, 0)
            }}
          />
        </div>
      </div>
    )
  }
}

// 重新设置连接之后组件的关联类型
const trainListForm = Form.create<ITrainListProps>()(TrainList)
const TrainListConnect: React.ComponentClass<ITrainListProps, ITrainListStates> = connectAlita([
  {},
])(trainListForm)
export default withRouter(TrainListConnect)
