import * as React from 'react'
import { Form, Input, Button, Modal, message, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { modPwd } from '../../service/index'
import umbrella from 'umbrella-storage'

export type IModPasswordProps = {
  onNextStep?: (e: any) => void
  studentId?: any
  icon: string
  buttonTitle: string
  onSuccess: () => any
} & FormComponentProps

type IModPasswordStates = {
  editDialogVisible: boolean
  loading: boolean
  accountId: any
}

class ModPassword extends React.Component<IModPasswordProps, IModPasswordStates> {
  constructor(props: any) {
    super(props)
    let user = umbrella.getLocalStorage('user')
    this.state = {
      editDialogVisible: false,
      loading: false,
      accountId: user.accountId,
    }
    this.showEditDialog = this.showEditDialog.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = async () => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        })
        const params = {
          ...values,
          accountId: this.state.accountId,
        }

        const res = await modPwd(params)
        if (res.success) {
          if (this.props.onNextStep) {
            this.props.onNextStep(null)
          }
          this.props.onSuccess()
          this.setState({
            editDialogVisible: false,
          })
          message.success('修改成功')
        } else {
          message.error(res.msg || '修改失败')
        }
        this.setState({
          loading: false,
        })
      }
    })
  }

  showEditDialog = () => {
    this.setState({
      editDialogVisible: true,
    })
  }

  handleCancel = (e: any) => {
    this.setState({
      editDialogVisible: false,
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    return (
      <div className="ml-m">
        <Modal
          width="40%"
          title="修改密码"
          visible={this.state.editDialogVisible}
          onOk={() => {
            this.handleSubmit()
          }}
          onCancel={this.handleCancel}
        >
          <Spin spinning={this.state.loading}>
            <Form layout="horizontal" style={{ marginBottom: 16 }}>
              <Form.Item label="密码">
                {getFieldDecorator('pwd', {
                  rules: [{ required: true, message: '请输入密码!' }],
                })(<Input.Password placeholder="密码" />)}
              </Form.Item>
              <Form.Item label="确认密码">
                {getFieldDecorator('rePwd', {
                  rules: [{ required: true, message: '请输入确认密码!' }],
                })(<Input.Password placeholder="确认密码" />)}
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        <Button
          icon={this.props.icon}
          type="ghost"
          ghost
          onClick={(e) => {
            setTimeout(() => {
              this.setState({ editDialogVisible: true })
              this.props.form.resetFields()
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<IModPasswordProps>()(ModPassword)
