import React from 'react'
import { Table, Form, Button, Pagination, Tag, Tabs, Popconfirm } from 'antd'
import InvoiceAdd from './InvoiceAdd'
import { myInvoiceList } from '../../service/index'
import { FormComponentProps } from 'antd/lib/form'
import InvoiceOrder from './InvoiceOrder'

const { Column } = Table
const { TabPane } = Tabs

export interface IInvoiceProps extends FormComponentProps {}
interface IInvoiceStates {
  dataSource?: any
  editInvoiceId: any
  pageSize: number
  page: number
  total: number
  checkedList: any
  loading: boolean
  invoiceStatus: any
}

class Invoice extends React.Component<IInvoiceProps, IInvoiceStates> {
  constructor(props: any) {
    super(props)
    this.getInvoiceList = this.getInvoiceList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      editInvoiceId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      checkedList: [],
      total: 0,
      loading: false,
      invoiceStatus: 0,
    }
  }
  componentDidMount() {
    this.getInvoiceList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getInvoiceList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...values,
      invoiceStatus: this.state.invoiceStatus == 0 ? '' : this.state.invoiceStatus,
    }
    const res = await myInvoiceList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getInvoiceList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getInvoiceList()
    }, 0)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        setTimeout(() => {
          this.setState({
            checkedList: selectedRowKeys,
          })
        }, 0)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div>
        <Form layout="inline" style={{ marginBottom: 16 }}>
          <Form.Item>
            <InvoiceOrder
              onReload={() => {
                this.handleSearch()
              }}
            />
          </Form.Item>
        </Form>
        <Tabs
          defaultActiveKey="0"
          onChange={(key: any) => {
            setTimeout(() => {
              this.setState({
                invoiceStatus: key,
              })
              this.handleSearch()
            }, 0)
          }}
        >
          <TabPane tab="全部" key="0"></TabPane>
          <TabPane tab="待审核" key="1"></TabPane>
          <TabPane tab="已审核" key="2"></TabPane>
          <TabPane tab="审核不通过" key="3"></TabPane>
          <TabPane tab="已开票" key="4"></TabPane>
          <TabPane tab="已送出" key="5"></TabPane>
        </Tabs>

        <Table
          loading={this.state.loading}
          rowKey="invoiceId"
          tableLayout="fixed"
          scroll={{ x: 1800 }}
          pagination={{
            current: this.state.page,
            onChange: this.onPageChange,
            pageSize: this.state.pageSize,
            size: 'small',
            total: this.state.total,
            showTotal: this.showTotal,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            onShowSizeChange: (current, size) => {
              setTimeout(() => {
                this.setState({
                  pageSize: size,
                })
                this.onPageChange(1)
              }, 0)
            },
          }}
          dataSource={this.state.dataSource}
        >
          <Column
            title="发票编号"
            dataIndex="invoiceNum"
            width={260}
            key="invoiceNum"
            render={(invoiceNum: any) => invoiceNum}
          />
          <Column
            title="申请时间"
            dataIndex="regtime"
            width={200}
            key="regtime"
            render={(regtime: any) => regtime}
          />
          <Column
            title="发票类型"
            dataIndex="invoiceType"
            key="invoiceType"
            render={(invoiceType: any) =>
              invoiceType == 1 ? '增值税专用发票' : '增值税普通发票（电子）'
            }
          />
          <Column
            title="订单编号"
            width={200}
            dataIndex="orderNum"
            key="orderNum"
            render={(orderNum: any) => orderNum}
          />
          <Column title="税号" dataIndex="taxNum" key="taxNum" render={(taxNum: any) => taxNum} />

          <Column
            title="备注"
            dataIndex="remark"
            key="remark"
            ellipsis
            render={(remark: any) => remark}
          />
          <Column
            title="开票金额"
            dataIndex="totalAmount"
            key="totalAmount"
            width={80}
            render={(totalAmount: any) => totalAmount}
          />
          <Column
            title="送达方式"
            dataIndex="receiveType"
            key="receiveType"
            width={80}
            render={(receiveType: any) => (receiveType == 1 ? '邮寄' : '电子邮箱')}
          />
          <Column
            title="状态"
            dataIndex="invoiceStatus"
            key="invoiceStatus"
            render={(invoiceStatus: any) => {
              let invoiceStatusStr = ''
              let tagColor = ''
              if (invoiceStatus === 1) {
                invoiceStatusStr = '待审核'
                tagColor = 'orange'
              } else if (invoiceStatus === 2) {
                invoiceStatusStr = '审核通过'
                tagColor = 'green'
              } else if (invoiceStatus === 3) {
                invoiceStatusStr = '审核不通过'
                tagColor = 'red'
              } else if (invoiceStatus === 4) {
                invoiceStatusStr = '已开票'
                tagColor = 'gray'
              } else if (invoiceStatus === 5) {
                invoiceStatusStr = '已送出'
                tagColor = 'gray'
              } else {
                invoiceStatusStr = ''
              }
              return <Tag color={tagColor}>{invoiceStatusStr}</Tag>
            }}
          />
          <Column
            title="操作"
            dataIndex="invoiceId"
            key="invoiceId"
            fixed="right"
            render={(invoiceId: any) => (
              <InvoiceAdd
                buttonTitle="详情"
                icon=""
                editable={false}
                invoiceId={invoiceId}
                onSuccess={() => {
                  this.onPageChange(1)
                }}
              />
            )}
          />
        </Table>
        <Pagination
          current={this.state.page}
          size="small"
          pageSizeOptions={['10', '20', '50', '100', '200']}
          pageSize={this.state.pageSize}
          showSizeChanger
          showQuickJumper
          total={this.state.total}
          showTotal={this.showTotal}
          onChange={this.onPageChange}
          onShowSizeChange={(current, size) => {
            setTimeout(() => {
              this.setState({
                pageSize: size,
              })
              this.onPageChange(1)
            }, 0)
          }}
        />
      </div>
    )
  }
}

export default Form.create<IInvoiceProps>()(Invoice)
