import React from 'react'
import { Card, Avatar, Tag, Tabs } from 'antd'
import Breadcrumb from '../Breadcrumb'
import { expertOne } from '../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { FormComponentProps } from 'antd/lib/form'
import { IMAGE_SERVER } from '../../service/config'

const { TabPane } = Tabs

export interface IExpertDetailProps extends FormComponentProps, RouteComponentProps<any> {}

interface IExpertDetailStates {
  expertId: String
  expert: any
}

class ExpertDetail extends React.Component<IExpertDetailProps, IExpertDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      expertId: props.query.id,
      expert: {},
    }
  }

  componentDidMount() {
    this.getExpertDetail()
  }

  async getExpertDetail() {
    const params = { expertId: this.state.expertId }
    const res = await expertOne(params)
    if (res.success) {
      setTimeout(() => {
        this.setState({
          expert: res.data[0],
        })
      }, 0)
    }
  }

  public render() {
    const { expert } = this.state
    return (
      <div className="app_layout_main">
        <Breadcrumb />
        <div className="expert-detail">
          <Card>
            <div className="flex-h header-left">
              <Avatar className="img" src={IMAGE_SERVER + expert.photoDirectURL} />
              <div className="header-right ml-l">
                <div className="title">{expert.expertName}</div>
                <div className="mt-m">
                  {expert.job && (
                    <Tag color="orange" className="mt-s">
                      {expert.job}
                    </Tag>
                  )}
                  {expert.jobTitleName && (
                    <Tag color="orange" className="mt-s">
                      {expert.jobTitleName}
                    </Tag>
                  )}
                  {expert.policital && (
                    <Tag color="orange" className="mt-s">
                      {expert.policital}
                    </Tag>
                  )}
                  {expert.industryName && (
                    <Tag color="orange" className="mt-s">
                      {expert.industryName}
                    </Tag>
                  )}
                  {expert.workComp && (
                    <Tag color="orange" className="mt-s">
                      {expert.workComp}
                    </Tag>
                  )}
                  {expert.graduateSch && (
                    <Tag color="orange" className="mt-s">
                      {expert.graduateSch}
                    </Tag>
                  )}
                  {expert.graduateSub && (
                    <Tag color="orange" className="mt-s">
                      {expert.graduateSub}
                    </Tag>
                  )}
                </div>
              </div>
            </div>
          </Card>
          <Card className="mt-m">
            <Tabs
              tabBarStyle={{
                borderBottomColor: 'transparent',
                display: 'flex',
                justifyContent: 'left',
              }}
            >
              <TabPane tab="讲师详情" key="cour_directory">
                <div className="flex-wrapper">{expert.abstract}</div>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    )
  }
}

export default ExpertDetail
