/**
 * Created by hao.cheng on 2017/4/22.
 */
import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import routes from '../routes/config'

type BreadcrumbCustomProps = RouteComponentProps<any> & {}

type BreadcrumbCustomStates = {
  breadcrumbs: any[]
}
class BreadcrumbCustom extends React.Component<BreadcrumbCustomProps, BreadcrumbCustomStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      breadcrumbs: [],
    }
  }
  componentDidMount() {
    const { location } = this.props
    const { pathname } = location
    const pathArr = pathname.split('/')
    const breadLen = pathArr.length - 2
    for (let index = 0; index < breadLen; index++) {
      this.getPathTitle(index)
    }
  }

  getPathTitle(index: number) {
    const { location } = this.props
    const { pathname } = location
    const menus = [...routes.menus, ...routes.others]
    const pathArr = pathname.split('/')
    const firstGuideArray = pathArr.slice(0, 3 + index)
    const firstPath = firstGuideArray.join('/')
    const selectMenu = menus.find((item) => firstPath == item.key)
    const title = selectMenu ? selectMenu!!.title : ''

    const { breadcrumbs } = this.state
    breadcrumbs.push({ title: title, path: firstPath })
    this.setState({ breadcrumbs })
  }

  render() {
    return (
      <span>
        <Breadcrumb style={{ margin: '12px 0' }}>
          {this.state.breadcrumbs.map((item) => (
            <Breadcrumb.Item key={item.path}>
              <Link to={item.path}>{item.title}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </span>
    )
  }
}

export default withRouter(BreadcrumbCustom)
