import * as React from 'react'
import { Form, Radio, Input, Button, Select, Modal, message, Spin, DatePicker } from 'antd'
import Uploader from './../widget/Uploader'
import { FormComponentProps } from 'antd/lib/form'
import { saveCompleteStu, userDetail } from './../../service/index'
import GlobalRegionCascader from './../widget/GlobalRegionCascader'
import CompInput from './../widget/CompInput'
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input

export type IEditProfileProps = {
  onNextStep?: (e: any) => void
  studentId?: any
  icon: string
  buttonTitle: string
  onSuccess: () => any
} & FormComponentProps

type IEditProfileStates = {
  groupId: any
  gender: any
  studentType: number
  photoDirectURL: any
  regiProvinId: any
  regiCityId: any
  provinName: any
  cityName: any
  compName: any
  compId: any
  groupName: any
  editDialogVisible: boolean
  isSetFile: any
  loading: boolean
  originCompName: any
  originCompId: any
}

class EditProfile extends React.Component<IEditProfileProps, IEditProfileStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      groupId: '',
      gender: '男',
      studentType: 1,
      photoDirectURL: '',
      regiProvinId: 0,
      regiCityId: 0,
      provinName: '',
      cityName: '',
      compName: '',
      compId: '',
      groupName: '',
      editDialogVisible: false,
      isSetFile: 0,
      loading: false,
      originCompName: '',
      originCompId: '',
    }

    this.showEditDialog = this.showEditDialog.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  getStudent = async () => {
    if (this.props.studentId) {
      this.setState({ loading: true })
      const res = await userDetail(this.props.studentId)
      if (res.success) {
        const accJson = res.data[0].accJson
        if (accJson && accJson.length > 0) {
          const stu = accJson[0]
          if (stu) {
            const group = stu.groupJson ? stu.groupJson[0] : {}
            const dateFormat = 'YYYY-MM-DD'
            const birthday = moment(stu.birthday, dateFormat)
            if (stu.birthday) {
              this.props.form.setFieldsValue({
                date: birthday,
              })
            }
            this.props.form.setFieldsValue({
              ...stu,
              compType: stu.compType + '',
              vehicleType: stu.vehicleType + '',
              ownerType: stu.ownerType + '',
              groupId: group ? group.groupId : '',
              region: [stu.regiProvinId, stu.regiCityId],
            })
            setTimeout(() => {
              this.setState({
                ...stu,
                groupId: group ? group.groupId : '',
                groupName: group ? group.groupName : '',
                originCompId: stu.compId,
                originCompName: stu.compName,
              })
            }, 0)
          }
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  handleSubmit = async () => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        })
        const dateFormat = 'YYYY-MM-DD'
        const birthday = moment(values.date).format(dateFormat)
        const params = {
          ...values,
          studentId: this.props.studentId,
          groupId: this.state.groupId,
          fileJson: JSON.stringify(values.fileList),
          isSetFile: this.state.isSetFile,
          regiProvinId: this.state.regiProvinId,
          regiCityId: this.state.regiCityId,
          provinName: this.state.provinName,
          cityName: this.state.cityName,
          compName: this.state.compName,
          compId: this.state.compId,
          studentType: 1,
          region: [],
          birthday: birthday,
          date: [],
        }
        const res = await saveCompleteStu(params)
        if (res.success) {
          if (this.props.onNextStep) {
            this.props.onNextStep(null)
          }
          this.props.onSuccess()
          this.setState({
            editDialogVisible: false,
          })
        } else {
          message.error(res.msg)
        }
        this.setState({
          loading: false,
        })
      }
    })
  }

  showEditDialog = () => {
    this.setState({
      editDialogVisible: true,
    })
  }

  handleCancel = (e: any) => {
    this.setState({
      editDialogVisible: false,
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    return (
      <div>
        <Modal
          width="40%"
          title="添加考生"
          visible={this.state.editDialogVisible}
          onOk={() => {
            this.handleSubmit()
          }}
          onCancel={this.handleCancel}
        >
          <Spin spinning={this.state.loading}>
            <Form layout="horizontal" style={{ marginBottom: 16 }}>
              <Form.Item label="学员姓名">
                {getFieldDecorator('stuName', {
                  rules: [{ required: true, message: '请输入姓名!' }],
                })(<Input placeholder="学员姓名" />)}
              </Form.Item>
              <Form.Item label="性别">
                {getFieldDecorator('gender', {
                  rules: [{ required: true, message: '请选择性别!' }],
                })(
                  <Radio.Group
                    onChange={(e) => {
                      console.log(e)
                      this.setState({
                        gender: e.target.value,
                      })
                    }}
                  >
                    <Radio value={'男'} key={'男'}>
                      男
                    </Radio>
                    <Radio value={'女'} key={'女'}>
                      女
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="学员头像">
                {getFieldDecorator('fileList', {
                  valuePropName: 'fileList',
                  rules: [
                    {
                      required: true,
                      message: '请上传学员头像!',
                      validator: (rule, value, callback) => {
                        if (
                          this.state.isSetFile === 0 &&
                          this.props.studentId &&
                          this.state.photoDirectURL
                        ) {
                          callback()
                        } else {
                          if (!form.getFieldValue('fileList')) {
                            callback('请上传学员头像!')
                          } else {
                            callback()
                          }
                        }
                      },
                    },
                  ],
                  normalize(e: any) {
                    if (Array.isArray(e)) return e
                    return e && e.fileList
                  },
                })(
                  <Uploader
                    defaultImg={this.state.photoDirectURL}
                    handleUploadFileChange={(fileList: any) => {
                      form.setFieldsValue({
                        fileList: fileList,
                      })
                      this.setState({
                        isSetFile: fileList.length > 0 ? 1 : 0,
                      })
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="手机号">
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: '请输入手机号!' }],
                })(<Input placeholder="手机号" allowClear />)}
              </Form.Item>
              <Form.Item label="身份证号">
                {getFieldDecorator('cardID', {
                  rules: [{ required: true, message: '请输入身份证号!' }],
                })(<Input placeholder="身份证号" allowClear />)}
              </Form.Item>
              <Form.Item label="出生日期">{getFieldDecorator('date')(<DatePicker />)}</Form.Item>
              <Form.Item label="电子邮箱">
                {getFieldDecorator('email', {
                  rules: [{ required: false, message: '请输入电子邮箱!' }],
                })(<Input placeholder="电子邮箱" allowClear />)}
              </Form.Item>
              <Form.Item label="文化程度">
                {getFieldDecorator('educDegree', {
                  rules: [{ required: false, message: '请选择文化程度!' }],
                })(
                  <Select style={{ width: 120 }} allowClear>
                    <Option key="大专" value="大专">
                      大专
                    </Option>
                    <Option key="本科" value="本科">
                      本科
                    </Option>
                    <Option key="硕士研究生" value="硕士研究生">
                      硕士研究生
                    </Option>
                    <Option key="博士研究生" value="博士研究生">
                      博士研究生
                    </Option>
                    <Option key="博士后" value="博士后">
                      博士后
                    </Option>
                    <Option key="其它" value="其它">
                      其它
                    </Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="民族">
                {getFieldDecorator('nation', {
                  rules: [{ required: false, message: '请输入民族!' }],
                })(<Input placeholder="民族" allowClear />)}
              </Form.Item>
              <Form.Item label="政治面貌">
                {getFieldDecorator('policital', {
                  rules: [{ required: false, message: '请选择政治面貌!' }],
                })(
                  <Select style={{ width: 120 }} allowClear>
                    <Option key="群众" value="群众">
                      群众
                    </Option>
                    <Option key="共青团员" value="共青团员">
                      共青团员
                    </Option>
                    <Option key="中共党员" value="中共党员">
                      中共党员
                    </Option>
                    <Option key="其它" value="其它">
                      其它
                    </Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="集体及主管单位">
                {getFieldDecorator('bigCompName', {
                  rules: [{ required: false, message: '请输入集体及主管单位!' }],
                })(<Input placeholder="集体及主管单位" allowClear />)}
              </Form.Item>
              <CompInput
                form={form}
                name={this.state.originCompName}
                id={this.state.originCompId}
                onChange={(values: any) => {
                  setTimeout(() => {
                    this.setState({
                      ...values,
                    })
                  }, 0)
                }}
              />
              <Form.Item label="部门名称">
                {getFieldDecorator('deptName', {
                  rules: [{ required: false, message: '请输入部门名称!' }],
                })(<Input placeholder="部门名称" />)}
              </Form.Item>
              <Form.Item label="职务">
                {getFieldDecorator('job')(<Input placeholder="职务" />)}
              </Form.Item>
              <Form.Item label="职称">
                {getFieldDecorator('jobTitle')(
                  <Select style={{ width: 120 }} allowClear>
                    <Option key="初级" value="初级">
                      初级
                    </Option>
                    <Option key="中级" value="中级">
                      中级
                    </Option>
                    <Option key="副高" value="副高">
                      副高
                    </Option>
                    <Option key="正高" value="正高">
                      正高
                    </Option>
                    <Option key="其他" value="其他">
                      其他
                    </Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="所属区域">
                {getFieldDecorator('region', {
                  rules: [{ required: false, message: '请选择所属地区!' }],
                })(
                  <GlobalRegionCascader
                    maxLevel={2}
                    regiProvinId={this.state.regiProvinId}
                    provinName={this.state.provinName}
                    regiCityId={this.state.regiCityId}
                    cityName={this.state.cityName}
                    onChange={(e: any) => {
                      if (e.length > 0) {
                        const value = e[0]
                        this.setState({
                          regiProvinId: value.value,
                          provinName: value.label,
                        })
                      }
                      if (e.length > 1) {
                        const value = e[1]
                        this.setState({
                          regiCityId: value.value,
                          cityName: value.label,
                        })
                      }
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="通讯地址">
                {getFieldDecorator('familyAddr')(<Input placeholder="通讯地址" />)}
              </Form.Item>
              <Form.Item label="邮政编码">
                {getFieldDecorator('postalCode')(<Input placeholder="邮政编码" />)}
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        <Button
          icon={this.props.icon}
          type="ghost"
          ghost
          onClick={(e) => {
            setTimeout(() => {
              this.setState({ editDialogVisible: true })
              this.getStudent()
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<IEditProfileProps>()(EditProfile)
