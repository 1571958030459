import React from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import NotFound from './components/pages/NotFound'
import Register from './components/pages/Register'
import App from './App'

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/app/home" push />} />
      <Route path="/app" component={App} />
      <Route path="/404" component={NotFound} />
      <Route path="/register" component={Register} />
      <Route component={NotFound} />
    </Switch>
  </Router>
)
