import React from 'react'
import { Pagination, Form } from 'antd'
import Breadcrumb from '../Breadcrumb'
import { channelList } from '../../service/index'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { FormComponentProps } from 'antd/lib/form'
import { IMAGE_SERVER } from '../../service/config'
import Login from '../pages/Login'
import { hasLogin } from '../../utils'
import liveUnstartImg from '../../style/imgs/live-unstart.png'
import liveStartedImg from '../../style/imgs/live-started.gif'
import liveFinishedImg from '../../style/imgs/live-finished.png'

export interface IChannelListProps extends FormComponentProps, RouteComponentProps<any> {}

interface IChannelListStates {
  type: String
  querySearchText: any
  searchText: any
  channelList: any[]
  page: number
  pageSize: number
  total: number
}

class ChannelList extends React.Component<IChannelListProps, IChannelListStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      type: props.query.type,
      querySearchText: '',
      searchText: '',
      channelList: [],
      page: 1,
      pageSize: 20,
      total: 0,
    }
  }

  componentDidMount() {
    this.initData()
  }

  initData() {
    this.getChannelList()
  }

  async getChannelList() {
    const params = { page: this.state.page, rows: this.state.pageSize }
    const res = await channelList(params)
    if (res.success) {
      setTimeout(() => {
        this.setState({
          channelList: res.data,
          total: res.total,
        })
      }, 0)
    }
  }

  public render() {
    return (
      <div className="app_layout_main">
        <Breadcrumb />
        <div className="flex-wrapper">
          {this.state.channelList &&
            this.state.channelList.map((item: any) => {
              let channelImg = item.banner
              if (item.coverDirectURL) {
                channelImg = IMAGE_SERVER + item.coverDirectURL
              }

              let statusDiv = <div></div>
              if (item.liveStatus == 0) {
                // 未开始
                statusDiv = (
                  <div className="live-status unstart">
                    <img src={liveUnstartImg} alt="" />
                  </div>
                )
              } else if (item.liveStatus == 1) {
                // 直播中
                statusDiv = (
                  <div className="live-status started">
                    <img src={liveStartedImg} alt="" />
                  </div>
                )
              } else if (item.liveStatus == 2) {
                // 已结束
                statusDiv = (
                  <div className="live-status finished">
                    <img src={liveFinishedImg} alt="" />
                  </div>
                )
              }

              const hasLoginDiv = (
                <a href={`${item.watchPCUrl}?token=${item.token}`} target="_blank" color="gray">
                  <div className="course-box mr-m live">
                    <img className="img" src={channelImg} alt="" />
                    <div className="title">{item.channelName}</div>
                    {statusDiv}
                  </div>
                </a>
              )

              const notLoginDiv = (
                <Login
                  slot={
                    <div className="course-box mr-m live" onClick={() => {}}>
                      <img className="img" src={channelImg} alt="" />
                      <div className="title">{item.channelName}</div>
                      {statusDiv}
                    </div>
                  }
                />
              )

              return hasLogin() ? hasLoginDiv : notLoginDiv
            })}
        </div>
        <div className="flex-h-end mt-m">
          <Pagination
            current={this.state.page}
            pageSize={this.state.pageSize}
            total={this.state.total}
            onChange={(page: any) => {
              setTimeout(() => {
                this.setState({
                  page: page,
                })

                this.getChannelList()
              }, 0)
            }}
          />
        </div>
      </div>
    )
  }
}

export default ChannelList
