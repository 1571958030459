import React, { Component } from 'react'

import { Modal, Button, Spin } from 'antd'
import { IMAGE_SERVER } from '../../service/config'
import { getFileType } from './../../utils/index'
import Pdfh5 from 'pdfh5'
import 'video-react/dist/video-react.css' // import css
import {
  Player,
  ControlBar,
  VolumeMenuButton,
  CurrentTimeDisplay,
  PlayToggle,
  FullscreenToggle,
  ProgressControl,
} from 'video-react'
import CustomProgressControl from './video/CustomProgressControl'
import { Link } from 'react-router-dom'

export default class WZFilePreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editDialogVisible: false,
      pdfh5: null,
      loading: false,
      lastPage: 0,
      lastTime: 0.0,
    }
  }

  componentDidMount() {
    this.loadDocVideo(this.props.fileName)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fileName !== this.props.fileName) {
      this.setState({
        fileName: nextProps.fileName,
      })
      this.loadDocVideo(nextProps.fileName)
    }
  }

  loadDocVideo(fileName) {
    const fileType = getFileType(fileName)
    if (this.props.modal === false && fileType !== 'video' && fileType !== 'image') {
      this.setState({
        loading: true,
      })
      setTimeout(() => {
        const pdfh5 = new Pdfh5('#pdfdemo', {
          pdfurl: IMAGE_SERVER + this.props.pdfUrl,
          fullscreen: true
        })
        this.setState({
          pdfh5: pdfh5,
        })
        //监听完成事件
        pdfh5.on('ready', (status, msg, time) => {
          this.setState({
            loading: false,
          })
        })
        pdfh5.on('scroll', (scrollTop, currentNum) => {
          const { lastPage } = this.state
          if (currentNum > lastPage && currentNum != lastPage) {
            if (this.props.onPDFPageChanged) {
              this.props.onPDFPageChanged(currentNum)
              this.setState({ lastPage: currentNum })
            }
          }
        })
      }, 2000)
    }
  }

  render() {
    const fileType = getFileType(this.props.fileName)
    const { url, pdfUrl, enrollLink, disable, isOverDate } = this.props
    const width = this.props.modal ? 'auto' : '800px'
    const height = this.props.modal ? 'auto' : '600px'
    const disableDiv = disable ? (
      <div className="disable">
        {/* {!isOverDate && (
          <Link className="mr-m" to={enrollLink} color="gray">
            <Button type="primary">请先报名</Button>
          </Link>
        )}
        {isOverDate && (
          <Button type="primary" disabled>
            此课程已过期
          </Button>
        )} */}
      </div>
    ) : (
      <div>
        {/* {isOverDate && (
          <div className="disable">
            <Button type="primary" disabled>
              此课程已过期
            </Button>
          </div>
        )} */}
      </div>
    )

    const player = (
      <Player
        autoPlay={!disable}
        startTime={0}
        onTimeUpdate={(e) => {
          var etime = e.target.currentTime
          const { lastTime } = this.state
          if (this.props.onVideoTimeUpdate && etime - lastTime >= 5) {
            this.props.onVideoTimeUpdate(etime)
            this.setState({
              lastTime: etime,
            })
          }
        }}
      >
        <source src={IMAGE_SERVER + this.props.url} />
        <ControlBar disableDefaultControls>
          <PlayToggle />
          <VolumeMenuButton vertical order={2.1} />
          <CurrentTimeDisplay order={4.1} />
          <CustomProgressControl order={5.1} />
          <FullscreenToggle order={6.1} />
        </ControlBar>
      </Player>
    )

    return (
      <div
        className="w-h-100 flex-center"
        style={{ maxWidth: width, margin: 'auto', maxHeight: height }}
      >
        {this.props.modal && (
          <Modal
            width={'60%'}
            title={this.props.fileName}
            visible={this.state.editDialogVisible}
            onOk={(e) => {
              this.setState({
                editDialogVisible: false,
              })
            }}
            onCancel={(e) => {
              this.setState({
                editDialogVisible: false,
              })
            }}
          >
            <div className="play-container">
              {fileType === 'video' && player}

              {fileType === 'image' && (
                <img alt="" style={{ width: '100%' }} src={IMAGE_SERVER + url} />
              )}

              {fileType !== 'video' && fileType !== 'image' && (
                <Spin spinning={this.state.loading}>
                  <div id="pdfdemo"></div>
                </Spin>
              )}
              {disableDiv}
            </div>
          </Modal>
        )}
        <div className="w-h-100 play-container">
          {!this.props.modal && fileType === 'video' && player}
          {!this.props.modal && fileType === 'image' && (
            <img alt="" style={{ width: '100%' }} src={IMAGE_SERVER + url} />
          )}
          {!this.props.modal && fileType !== 'image' && fileType !== 'video' && (
            <div className="w-h-100 flex-center">
              <div id="pdfdemo" className="">
                
                <Link target="_blank" to={'/app/train/pdf?url=' + escape(pdfUrl)} color="gray">
                  <div className="pdf-fullscreen">
                  </div>
                </Link>
              </div>
              <Spin spinning={this.state.loading}></Spin>
            </div>
          )}
          {disableDiv}
        </div>
        {this.props.modal && (
          <div className="play-container">
            {fileType === 'video' && (
              <Button
                type="primary"
                shape="circle"
                icon="video-camera"
                onClick={() => {
                  this.setState({
                    editDialogVisible: true,
                  })
                }}
              ></Button>
            )}
            {fileType !== 'video' && fileType !== 'image' && (
              <Button
                type="primary"
                shape="circle"
                icon="file-pdf"
                onClick={() => {
                  this.setState({
                    editDialogVisible: true,
                  })
                  this.setState({
                    loading: true,
                  })
                  setTimeout(() => {
                    const pdfh5 = new Pdfh5('#pdfdemo', {
                      pdfurl: IMAGE_SERVER + pdfUrl,
                      fullscreen: true
                      
                    })
                    this.setState({
                      pdfh5: pdfh5,
                    })
                    //监听完成事件
                    // pdfh5.on('ready', (status, msg, time) => {
                    //   this.setState({
                    //     loading: false,
                    //   })
                    // })
                    pdfh5.on('complete', (status, msg, time) => {
                      this.setState({
                        loading: false,
                      })
                    })
                  }, 2000)
                }}
              ></Button>
            )}
            {fileType === 'image' && (
              <Button
                type="primary"
                shape="circle"
                icon="file-image"
                onClick={() => {
                  this.setState({
                    editDialogVisible: true,
                  })
                }}
              ></Button>
            )}
            {disableDiv}
          </div>
        )}
      </div>
    )
  }
}
