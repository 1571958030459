import * as React from 'react'
import { Progress } from 'antd'
import { Link } from 'react-router-dom'
import { IMAGE_SERVER } from '../../service/config'
import course_bg1 from './../../style/imgs/course_bg1.png'

export interface ICourseCellProps {
  course: any
  modal: boolean
  type: any
  typeId: any
  isEnroll: any
}

export default class CourseCell extends React.Component<ICourseCellProps> {
  public render() {
    const { course, type, typeId, isEnroll } = this.props
    const percent = Math.ceil((course.learnCourDura / course.courDura) * 100)
    return (
      <Link
        to={`/app/train/course?id=${course.courseId}&type=${type}&typeId=${typeId}`}
        color="gray"
      >
        <div className="course-box mr-m">
          {course.coverDirectURL && (
            <img className="img" src={IMAGE_SERVER + course.coverDirectURL} alt="" />
          )}
          {!course.coverDirectURL && <img className="img" src={course_bg1} alt="" />}

          <div className="title">{course.title}</div>
          {isEnroll && (
            <div className="flex mt-s">
              学习进度：
              <div style={{ width: 120 }}>
                <Progress percent={percent} size="small" />
              </div>
            </div>
          )}
        </div>
      </Link>
    )
  }
}
