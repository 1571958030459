/*
 * File: Copyright.tsx
 * Desc: 版权信息
 * File Created: 2020-04-12 22:50:33
 * Author: chenghao
 * ------
 * Copyright 2020 - present, karakal
 */
import React from 'react'
import { COPY_RIGHT, ICP } from './../../service/config'

const Copyright = () => {
  return (
    <div className="footer">
      <div>
        ©{new Date().getFullYear()} 版权所有 {COPY_RIGHT}
      </div>
      {/* <div>©{new Date().getFullYear()} 版权所有 浙江省混凝土协会</div> */}
      <div className="mt-s">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          {ICP}
        </a>
        <span className="ml-m">技术支持：浙江网泽科技有限公司</span>
      </div>
    </div>
  )
}

export default Copyright
